<style src="./user-feedback.component.scss" lang="scss"></style>

<template>
  <div class="user-feedback">
    <div class="row">
      <div class="col-12">
        <v-card class=" d-lg-block d-none">
          <div class="row">
            <div class="col-md-6">
              <h3>
                {{ locale.feedback }}
              </h3>
            </div>
            <div class="col-md-6">
              <SearchComponent @searchWord="sendSearch"/>
            </div>
          </div>
          <v-table class="mt-20 mb-20" v-if="totalCountFeedBack>0">
            <template #header>
              <tr>
                <th>Qurum adı</th>
                <th>Xidmət adı</th>
                <th>Müraciət tarixi</th>
<!--                <th class="text-center">Status</th>-->
              </tr>
            </template>
            <template #body>
              <tr v-for="item in feedbacks" @click="redirectAnswer(item.statusId,item.id)">
                <td>
                  <template v-if="item.structureName?.length>0">{{ item.structureName }}</template>
                  <template v-else> {{ item.feedbackTypeName }}</template>
                </td>
                <td>

                  <template v-if="item.serviceName?.length>0">{{ item.serviceName }}</template>
                  <template v-else> {{ item.feedbackTypeName }}</template>
                </td>
                <td>{{ item.date }}</td>
<!--                <td class="text-center">-->
<!--                  <div class="feedback-status" :class="[{'feedback-status&#45;&#45;blue':item.statusId === 2},{'feedback-status&#45;&#45;green':item.statusId === 1},{'feedback-status&#45;&#45;green':item.statusId === 3}]">-->
<!--                    {{ item.status }}-->

<!--                  </div>-->
<!--                </td>-->
              </tr>
            </template>
          </v-table>
          <v-alert color="warning" v-else>
            {{ locale.noData }}
          </v-alert>
          <v-pagination
              ref="pagination"
              :limit="pageSize"
              :dataCount="totalCountFeedBack"
              :page="pageNumber"
              @changePage="changePage"
              class="mt-40 mb-100  "/>

        </v-card>
        <div class="mobile d-lg-none d-block">
          <h3>
            {{ locale.feedback }}
          </h3>
          <SearchComponent @searchWord="sendSearch"/>
          <apply-mobile-component v-if="totalCountFeedBack>0" :data="feedbacks"/>
          <v-alert color="warning" v-else>
            {{ locale.noData }}
          </v-alert>
          <div class="text-center">
            <v-pagination
                ref="pagination"
                :limit="pageSize"
                :dataCount="totalCountFeedBack"
                :page="pageNumber"
                @changePage="changePage"
                class="mt-40 mb-100  "/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script src="./user-feedback.component.ts"></script>
