import 'reflect-metadata';
import {store} from '@/store';
import {Options, Vue} from 'vue-class-component';
import TableComponent from '@/packages/VTable/table.component.vue';
import SearchComponent from '@/pages/user-account/components/user-search/search.component.vue';
import PaginationComponent from '@/packages/VPagination/pagination.component.vue';
import {locale} from '@/assets/db/db.conf.ts';
import {container} from 'tsyringe';
import {UserFeedbackSandbox} from '@/pages/user-feedback/sandbox/user-feedback.sandbox';
import {IFeedbackUser} from '@/pages/user-feedback/types/feedback';
import ApplyMobileComponent from '@/core/shared/apply-mobile/apply-mobile.component.vue';

@Options({
    name: 'user-feedback-component',
    components: {
        TableComponent,
        SearchComponent,
        PaginationComponent,
        ApplyMobileComponent
    }
})

export default class UserFeedbackComponent extends Vue {
    private sandbox: UserFeedbackSandbox = container.resolve(UserFeedbackSandbox);
    public locale$: any = locale;
    public locale: any = null;
    public store = store;
    public pageNumber = 1;
    public pageSize = 10;
    public word: string = '';

    public $refs!: {
        pagination: {
            reset: () => void;
        },
    };

    get feedbacks(): IFeedbackUser[] {
        return this.store.getters._getFeedBackAll;
    }

    get totalCountFeedBack(): number {
        return this.store.getters._getTotalCountFeedBackAll;
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.user) {
                this.locale = res.user;
            }
        });
    }

    public clickFeedback(id: number, statusId: number) {
        if (statusId === 2) {
            this.$router.push({name: 'feedback-answer', params: {id: id}});

        }
    }

    public mounted() {
        this.sandbox.getFeedBackAll({
            token: localStorage.getItem('mainToken'),
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
        });
    }

    public changePage(info: any) {
        this.pageNumber = info.pageNumber;
        this.pageSize = info.pageSize;
        this.sandbox.getFeedBackAll({
            token: localStorage.getItem('mainToken'),
            pageSize: info.pageSize,
            pageNumber: info.pageNumber,
            word: this.word
        });
        window.scroll(0, 0);
    }

    public sendSearch(info: string) {
        this.word = info;
        this.resetPagination();
        this.sandbox.getFeedBackAll({
            token: localStorage.getItem('mainToken'),
            pageSize: 10,
            pageNumber: 1,
            word: this.word
        });
    }

    public resetPagination() {
        if (this.pageNumber > 1) {
            this.$refs.pagination.reset();
            this.pageNumber = 1;
        }
    }

    public redirectAnswer(statusId: number, id: number) {
        // if (statusId === 2) {
        //     this.$router.push({name: 'feedback-answer', params: {id: id}});
        // }
    }
}
